@use "sass:color";
@use "../_config/colors";
@use "../mixins/mq";

video,
img {
	width: 100%;
	height: auto;
	display: block;
	object-fit: cover;
}
picture {
	display: block;
}



.figure {
	position: relative;
	overflow: hidden;

	&:after {
		position: absolute;
		top: 0;
		display: block;
		width: 100%;
		height: 100%;
		content: '';
	}

	picture,
	img,
	video {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
	}

	img {
		transition: transform var(--animationDuration) var(--animationEase);
		image-rendering: -webkit-optimize-contrast;
		backface-visibility: hidden;
	}

	&-three {
		height: 0;
		padding-bottom: 77.1%;
	}

	&-two {
		height: 0;
		padding-bottom: 87.5%;
	}

	&-small {
		padding-bottom: 100%;
		width: 100%;
	}
	&-portrait {
		padding-bottom: 130.6122449%;

	}

	&-cta:after {
		background-color: color.adjust(colors.$color-black, $alpha: -0.55);
	}
	&-fullscreen {
		&:after {
	//		background: linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,.4) 100%);
		}
	}
}

.figure-slider--small {
	height: 0;
	padding-bottom: 100%;

	@include mq.mq($from: medium) {
		padding-bottom: 54.91%;
	}
}

.figure-slider--tall {
	height: 0;
	padding-bottom: 100%;

	@include mq.mq($from: medium) {
		padding-bottom: 77.56%;
	}
}

.figure-cover {

	picture,
	img {
			position: relative;
			display: block;
	}

}

video::-webkit-media-controls,
video::video::-webkit-media-controls-enclosure,
video::-webkit-media-controls-overlay-play-button {
	display: none !important;
	-webkit-appearance: none !important;

}

// TODO WEBGL
/*.figure-three {
	img {
		opacity: 0;
	}

	&:after {
		display: none;
	}
}*/
