@use "../mixins/mq";

.section {
	position: relative;
	overflow: hidden;

	&--overflow {
			overflow: visible;
	}

}

.section--spacing {
	padding-top: var(--spacing-40);
	padding-bottom: var(--spacing-40);

	@include mq.mq($from: large) {
		padding-top: var(--spacing-100);
		padding-bottom: var(--spacing-100);
	}

	.component--entries & {
		@include mq.mq($from: large) {
			padding-top: var(--spacing-120);
		}	
	}

}


.section--spacing-top {
	
	padding-top: var(--spacing-40);

	@include mq.mq($from: large) {
		padding-top: var(--spacing-100);
	}

}


.section__title {
	margin-bottom: var(--spacing-40);
}

#main {
	margin-top: var(--headerHeightSmall);
	
	@include mq.mq($from: large) {
		margin-top: var(--headerHeight);
	}

	.overlaid-header & {
		margin-top: 0;
	}
}