@use "../_base/svg";
@use "../_config/colors";
@use "../mixins/background-svg";

.overlay:before {
  content: "";
  z-index: 1;
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  @include background-svg.params(svg.$svg-overlay, transparent, center, center, no-repeat, 50%, auto, colors.$color-secondary-2);
}
