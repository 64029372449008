@use "../_base/svg";
@use "../_config/colors";
@use "../_base/typography";
@use "../mixins/background-svg";
@use "../mixins/mq";

.action {
	position: relative;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	max-width: 100%;
	overflow: visible;
	text-decoration: none;
	transition: background-color var(--animationDuration) var(--animationEase), color var(--animationDuration) var(--animationEase), border-color var(--animationDuration) var(--animationEase);
	cursor: pointer;

	&-link {

		color: inherit;
		font-size: 2.1rem;
		font-weight: 500;

		.icon {
			margin-right: 1.7rem;
			height: 4rem;
			width: 4rem;
			@include mq.mq($from: large) {
				height: 5.1rem;
				width: 5.1rem;
			}
		}
	}

	&:hover {
		text-decoration: underline;
	}

}

.action-primary {
	min-height: var(--button-primary-min-height);
	min-width: var(--button-primary-min-width);
	padding: 0 var(--spacing-20);
	letter-spacing: 0.85px;
	font-size: var(--font-size-button);
	@extend .font-weight-600;
	color: var(--color-white);

	border-radius: var(--button-radius);
	background-color: var(--color-primary-1);
	border: 1px solid var(--color-primary-1);

	&.disabled {
		background-color: var(--color-grey-200);
		border-color: var(--color-grey-200);
	}

	.not-touch & {
		&:hover {
			color: var(--color-white);
			background-color: var(--color-secondary-1);
			border-color: var(--color-secondary-1);
		}
	}
}

.nav--meta-nav a.nav__button {
	min-width: auto;
	padding: 0 38px;
}

.action-primary--inverted {
	@extend .action-primary;
	color: var(--color-primary-1);

	border-color: var(--color-primary-1);
	background-color: transparent;

	&.disabled {
			background-color: transparent;
		color: var(--color-grey-200);
	}

	.not-touch & {
		&:hover {
			color: var(--color-secondary-1);
			background-color: transparent;
		}
	}
}


.action-secondary {
	padding: 0;

	font-size: 2.1rem;
	color: var(--color-primary-1);
	letter-spacing: 1px;
	font-weight: 500;


		&:before {
			content: "";
			display: block;
			width: 6rem;
			height: 3rem;
			margin-right: .9rem;
			transition: background var(--animationDuration) var(--animationEase);

			@include background-svg.params(svg.$svg-cta-icon, transparent, center, center, no-repeat, 6rem, 3rem, colors.$color-primary-1);
		}

	&.disabled {
		color: var(--color-grey-200);
		border-color: var(--color-grey-200);

		&:before {
			@include background-svg.params(svg.$svg-cta-icon, transparent, center, center, no-repeat, 6rem, 3rem, colors.$color-grey-200);
		}
	}

	.not-touch & {
		&:hover {
			color: var(--color-secondary-1);
			&:before {
				@include background-svg.params(svg.$svg-cta-icon, transparent, center, center, no-repeat, 6rem, 3rem, colors.$color-secondary-1);
			}
		}
	}

	.font-color-white & {

	}
}

.action-secondary--inverted {
	@extend .action-secondary;
	color: var(--color-white);

	&:before {
		@include background-svg.params(svg.$svg-cta-icon, transparent, center, center, no-repeat, 6rem, 3rem, colors.$color-white);
	}

	&.disabled {

	}

	.not-touch & {
		&:hover {

		}
	}
}

.action-fab {
	width: 6rem;
	height: 6rem;
	border-radius: 50%;
	@include background-svg.params(svg.$svg-arrow-right, transparent, center, center, no-repeat, 3rem, 2.1rem, colors.$color-white);
	border: 0.1rem solid var(--color-grey-400);
	background-color: var(--color-grey-400);

	.not-touch & {
		&:hover {
			border-color: var(--color-primary-1);
			@include background-svg.params(svg.$svg-arrow-right, colors.$color-primary-1, center, center, no-repeat, 3rem, 2.1rem, colors.$color-white);
		}
	}

	.not-touch .c-three_columns-item:hover & {
		@include background-svg.params(svg.$svg-arrow-right, colors.$color-primary-1, center, center, no-repeat, 3rem, 2.1rem, colors.$color-white);
	}

	&--inverted {
		@include background-svg.params(svg.$svg-arrow-right, transparent, center, center, no-repeat, 3rem, 2.1rem, colors.$color-white);
		border-color: var(--color-white);

		.not-touch & {
			&:hover {
				border-color: var(--color-primary-1);
			}
		}
	}

	&.disabled {
		border-color: transparent;
		@include background-svg.params(svg.$svg-arrow-right, colors.$color-grey-100, center, center, no-repeat, 3rem, 2.1rem, colors.$color-grey-200);
		border-color: var(--color-grey-100);
	}
}



.action-phone,
.action-mail,
.action-download {
	@extend .action;
	color: var(--color-black);
	font-weight: 500;
	&:before {
		width: 4rem;
		height: 4rem;
		content: '';
		margin-right: var(--spacing-10);


	}
}

.action-download {
	&:before {
		@include background-svg.params(svg.$svg-download, transparent, center, center, no-repeat, 100%, 100%, colors.$color-primary-1);
	}
}

.action-phone {
	&:before {
		@include background-svg.params(svg.$svg-mobile, transparent, center, center, no-repeat, 100%, 100%, colors.$color-black);
	}
}

.action-mail {
	&:before {
		@include background-svg.params(svg.$svg-contact, transparent, center, center, no-repeat, 100%, 100%, colors.$color-black);
	}
}



.action-footer {
	color: var(--color-white);

	&:hover {
		text-decoration: underline;
	}
}






.action-dot {
	width: 5rem;
	height: 5rem;
	border-radius: 50%;
	@include background-svg.params(svg.$svg-plus, colors.$color-primary-1, center, center, no-repeat, 3rem, 3rem, colors.$color-white);
	transform: rotate(0deg);
	transition: transform var(--animationDuration) var(--animationEase);
	position: relative;

	&:before {
		content: "";
		display: block;
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		border-radius: 50%;
		border: var(--color-primary-1) 1px solid;
		transform: scale(1.1);
		animation: pulsing 2s infinite alternate;
	}

	&:hover {
		&:before {
			animation-duration: .5s;
			//transform: scale(1.4);
		}
	}

	&.active {
		transform: rotate(45deg);
		&:before {
			animation-duration: 99s;
		}
	}
}


@keyframes pulsing {
    from {
        transform: scale(1.1);
    }
    to {
        transform: scale(1.3);
    }
}
