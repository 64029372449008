@use "../_config/colors";
@use "../_config/spacing";
@use "../mixins/mq";

:root {
  --container-width: 160rem;

  --color-black: #{colors.$color-black};
  --color-grey-400:  #{colors.$color-grey-400};
  --color-grey-300:  #{colors.$color-grey-300};
  --color-grey-200:  #{colors.$color-grey-200};
  --color-grey-100:  #{colors.$color-grey-100};
  --color-white:  #{colors.$color-white};
  --color-primary-1:  #{colors.$color-primary-1};
  --color-primary-2:  #{colors.$color-primary-2};
  --color-secondary-1:  #{colors.$color-secondary-1};
  --color-secondary-2:  #{colors.$color-secondary-2};
  --color-error:  #{colors.$color-error};
  --color-success:  #{colors.$color-success};
  --color-warning:  #{colors.$color-warning};
  --color-black-transparent:  #{colors.$color-black-transparent};
  --color-primary-1-transparent:  #{colors.$color-primary-1-transparent};

  --spacing-200: #{spacing.$spacing-200};
  --spacing-160: #{spacing.$spacing-160};
  --spacing-140: #{spacing.$spacing-140};
  --spacing-120: #{spacing.$spacing-120};
  --spacing-100: #{spacing.$spacing-100};
  --spacing-80: #{spacing.$spacing-80};
  --spacing-60: #{spacing.$spacing-60};
  --spacing-50: #{spacing.$spacing-50};
  --spacing-40: #{spacing.$spacing-40};
  --spacing-30: #{spacing.$spacing-30};
  --spacing-20: #{spacing.$spacing-20};
  --spacing-10: #{spacing.$spacing-10};

  --grid-padding: var(--spacing-10);
  --wrapper-padding: var(--spacing-20);

  --spacing-paragraph: #{spacing.$spacing-30};

  --font-size-200: 1.4rem;
  --font-size-300: 1.6rem;
  --font-size-400: 1.9rem;
  --font-size-600: 2.1rem;
  --font-size-700: 2.6rem;
  --font-size-800: 3rem;
  --font-size-900: 3.6rem;
  --globalFontSize: var(--font-size-400);
  --globalLineHeight: var(--line-height-400);

  --line-height-200: 1.6;
  --line-height-300: 1.470588235;
  --line-height-400: 1.684210526;
  --line-height-600: 1.619047619;
  --line-height-700: 1.538461538;
  --line-height-800: 1.4;
  --line-height-900: 1.388888889;


  @include mq.mq($from: medium) {
    --grid-padding: var(--spacing-20);
    --wrapper-padding: var(--grid-padding);
  }


  @include mq.mq($from: large) {
    --font-size-200: 1.5rem;
    --font-size-300: 1.7rem;
    --font-size-400: 2.1rem;
    --font-size-600: 2.6rem;
    --font-size-700: 3.4rem;
    --font-size-800: 4.6rem;
    --font-size-900: 6rem;

    --line-height-200: 1.5;
    --line-height-300: 1.470588235;
    --line-height-400: 1.80952381;
    --line-height-600: 1.615384615;
    --line-height-700: 1.470588235;
    --line-height-800: 1.304347826;
    --line-height-900: 1.25;

    --grid-padding: var(--spacing-40);


    --spacing-paragraph: #{spacing.$spacing-60};
	}



  --font-size-button: 1.7rem;

  --button-primary-min-height: 5rem;
  --button-primary-min-width: 23rem;

  --button-radius: 1rem;


  --bodyFontFamily: "Cerebri Sans", Helvetica, Arial, sans-serif;
  --bodyFontColor: var(--color-black);

  --headerHeightSmall: 7rem;
  --headerHeight: 14rem;

  --hamburgerLineHeight: 2px;
  --hamburgerWidth: 40px;
  --hamburgerSpacing: 12px;

  --formBorder: 1px solid #999;
  --formBorderRadius: 4px;
  --formInputHeight: 33px;

  --animationDuration: 0.25s;
  --animationEase: cubic-bezier(.25,.46,.45,.75);
}

$bodyFontFamily: "Cerebri Sans", Helvetica, Arial, sans-serif;
