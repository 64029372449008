$fontPath: '../../webfonts/CerebriSans/';

@font-face {
	font-family: "Cerebri Sans";
	src: url('#{$fontPath}CerebriSansRegular.woff2') format('woff2'), 
		 url('#{$fontPath}CerebriSansRegular.woff') format('woff');
}
@font-face {
	font-family: "Cerebri Sans";
	src: url('#{$fontPath}CerebriSansLightItalic.woff2') format('woff2'), 
		 url('#{$fontPath}CerebriSansLightItalic.woff') format('woff');
	font-style: italic;
	font-weight: 300;
}
@font-face {
	font-family: "Cerebri Sans";
	src: url('#{$fontPath}CerebriSansMedium.woff2') format('woff2'), 
		 url('#{$fontPath}CerebriSansMedium.woff') format('woff');
	font-weight: 500;
}