.subheader {
  text-transform: uppercase;
  font-size: 1.5rem;
  display: inline-flex;
  align-items: center;
  gap: 2.7rem;
  letter-spacing: 0.1rem;

  &:before {
    content: "";
    display: block;
    width: 6rem;
    height: .4rem;
    background-color: var(--color-primary-1);
  }
}
