@use "../_base/grid";
@use "../mixins/mq";

$sizes: (
		'sm',
		'md',
		'lg'
);

.container {
	width: 100%;
	max-width: var(--container-width);
	margin-left: auto;
	margin-right: auto;
}

.row {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
}

.row--v-align-center {
	align-items: center;
}

.row--h-align-center {
	justify-content: center;
}

/* custum for team */
.row--offset-layout {
	@include mq.mq($from: medium, $until: large) {
		margin-bottom: 7rem;
			.col-md-6:nth-child(even) {
				margin: 7rem 0 -7rem;
			}
	}
	@include mq.mq($from: large) {
	margin-bottom: 12rem;
		.col-lg-4:nth-child(3n+2) {
			margin: 12rem 0 -12rem;
		}
		.col-lg-4:nth-child(3n+3) {
			margin: 4.5rem 0 -4.5rem;
		}
	}
}

.row--grid {
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	grid-column-gap: var(--spacing-10);
  	row-gap: var(--grid-row-gap,0);
	justify-content: center;
	grid-template-rows: auto;
	align-items: start;
	@include mq.mq($from: medium) {
		grid-column-gap: var(--spacing-20);
	}

	@include mq.mq($from: large) {
		grid-column-gap: var(--spacing-60);
	}
}

.grid-row-gap {
	&-1 {
		--grid-row-gap: 1rem;
	}
	&-2 {
		--grid-row-gap: 2rem;
	}
	&-3 {
		--grid-row-gap: 3rem;
	}
}



.row--reverse {
	@include mq.mq($from: medium) {
		direction: rtl;
	}

	* {
		direction: ltr;
		text-align: left;
	}
}

.col--half {
	width: 50%;
}

.wrapper {
	position: relative;
	width: 100%;
	padding-left: var(--wrapper-padding);
	padding-right: var(--wrapper-padding);


}

.middle {
	margin-left: auto;
	margin-right: auto;
}

.middle--medium {
	@include mq.mq($from: medium) {
		max-width: 56rem;
	}

	@include mq.mq($from: large) {
		max-width: 77rem;
	}
}

.middle--large {
	@include mq.mq($from: medium) {
		max-width: 56rem;
	}

	@include mq.mq($from: large) {
		max-width: 105rem;
	}
}

@mixin generateColumns($columns, $size, $breakpointStart: null, $breakpointEnd: null) {

	@if ($breakpointStart) {

		@include mq.mq($from: $breakpointStart, $until: $breakpointEnd) {

			@for $i from 1 through $columns {
				.col-#{$size}-#{$i} {
					grid-column-end: span $i;
				}

				.offset-#{$size}-#{$i} {
					grid-column-start: $i + 1
				}

			}
		}

	} @else {

		@for $i from 1 through $columns {

			.col-#{$size}-#{$i} {
				grid-column-end: span $i;
			}

			.offset-#{$size}-#{$i} {
				grid-column-start: $i + 1;
			}

		}
	}
}

@each $size in $sizes {

	@if $size == 'sm' {
		@include generateColumns(grid.$columns, $size);
	}

	@else if $size == 'md' {
		@include generateColumns(grid.$columns, $size, medium, large);
	}

	@else if $size == 'lg' {
		@include generateColumns(grid.$columns, $size, large);
	}
}
