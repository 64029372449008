@use "../mixins/mq";
@use "vars";

html {
	font-size: 62.5%;
	text-size-adjust: none;
}

body {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-rendering: optimizeLegibility;

	font-family: var(--bodyFontFamily, vars.$bodyFontFamily);
	font-size: var(--globalFontSize);
  line-height: var(--globalLineHeight);

	background-color: var(--color-white);
	hyphens: manual;
	font-variant: none;

	//background-color: black;

	word-break: break-word;
}

main {
	
}
.hidden {
	display: none!important;
}