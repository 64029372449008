@use "../mixins/mq";
@use "../mixins/background-svg";
@use "../_config/colors";
@use "../_base/svg";


:root {
	--pull-to-edge: var(--wrapper-padding);
  --slider-offdset: 12.4%;
  --slider-navigation: var(--slider-offdset);
	--sliderButtonPosition: -6.4rem;

	@include mq.mq($from: medium) {
		  --slider-offdset: 21%;
	}
	@include mq.mq($from: large) {
		--pull-to-edge: 0;
    --slider-offdset: 0;
		--sliderButtonPosition: -4rem;

	}
	@include mq.mq($from: xxlarge) {
		--sliderButtonPosition: -11.6rem;

	}
};


.swiper {
	user-select: none;
  position: relative;

  overflow: hidden;
	padding: 0 var(--slider-offdset,0);



  &-outer {
    position: relative;
    &--has-pagination {
			padding-bottom: 2rem;
        margin-bottom: 3rem;
    }

    &--pull-to-edge {
      margin-left: calc( var(--pull-to-edge) * -1);
      margin-right: calc( var(--pull-to-edge) * -1);
      
      .component--entries & {
        @include mq.mq($from: large) {
          margin-top: 108px;
        }	
      }
    }

		&--wide {
			--pull-to-edge: var(--wrapper-padding);

			@include mq.mq($from: large) {

				--pull-to-edge: var(--wrapper-padding);
				--slider-offdset: 27.1%;
			  --slider-navigation: 0;
				--sliderButtonPosition: 0;

			}
			@include mq.mq($from: xxlarge) {
				--sliderButtonPosition: -8rem;

			}


		}
  }

	&--fade-out-on-inactive {
		opacity: 0;
		transition: opacity var(--animationDuration) var(--animationEase);

    @include mq.mq($from: xlarge) {
      opacity: 1;
    }

		.swiper-slide-active & {
				opacity: 1;
		}

    @include mq.mq($from: large) {
      .swiper-slide-prev & {
        width: 59.4%;
        float: right;
      }
      .swiper-slide-next & {
        width: 59.4%;
      }
    }

	}

	&--link {
			display: block;
			text-decoration: none;
			color: inherit;

			// allow slideToClickedSlide
			pointer-events: none;
			.swiper-slide-active & {
					pointer-events: all;
			}
      @include mq.mq($from: large) {
        .swiper-slide-next & , .swiper-slide-prev & {
          padding: 30px;
        }
      }
	}

	&--section {
			padding: 3rem 0 0;
	}
	&--footer {
		padding: 2rem 0 0;
	}

  &-slide {
		height: auto;

		&--carouselle {
			transition: transform var(--animationDuration) var(--animationEase);
			&:not(.swiper-slide-active) {
				transform: scale(.88);
			}
		}

  }

  &-wrapper {

  }


  &-pagination {
    display: none;
    padding: 0 var(--slider-offdset,0);
    .swiper-outer--has-pagination & {
      display: block;
    }
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 1;
    height: 3rem;
    text-align: left;
    color: var(--color-grey-300);
    font-size: 1.6rem;
    letter-spacing: .8px;
    font-style: italic;
  }

	&-navigation {
		margin: 0 var(--slider-navigation,0);
		position: absolute;
    top: 37%;
    left: 0;
    right: 0;

	}


  &-button {
    &-prev {
      @include background-svg.params(svg.$svg-arrow-left, colors.$color-grey-400, center, center, no-repeat, 3rem, 3rem, colors.$color-white);
      left: var(--sliderButtonPosition,0);

    }
    &-next {
      @include background-svg.params(svg.$svg-arrow-right, colors.$color-grey-400, center, center, no-repeat, 3rem, 3rem, colors.$color-white);
      right: var(--sliderButtonPosition,0);
    }

    transition: background-color var(--animationDuration) var(--animationEase);
    &:hover {
      background-color: var(--color-primary-1);
    }

    display: none;
    position: absolute;
    top: 50%;
    border-radius: 50%;
    padding: 5px;
    height: 6rem;
    width: 6rem;
    transform: translateY(-50%) scale(67%);
    z-index: 1;

    @include mq.mq($from: medium) {
      display: block;
    }

    @include mq.mq($from: xxlarge) {
        transform: translateY(-50%) scale(1);
    }
    &:after {
			display: none !important;
		}
  }
}
.c-gallery {
  .swiper-navigation {
    top: 50%;
  }
}