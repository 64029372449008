@use "../mixins/mq";

.card {
	padding: 4rem 2rem 2rem;
	position: relative;
	background-color: var(--color-white);

	@include mq.mq($from: large) {
		padding: 4rem 4.2rem;
	}

	&--custum {
		@include mq.mq($from: medium, $until: large) {
			display: flex;;
    	gap: 3rem;
			> * {
				width: 100%;
			}
		}
	}

	&__header {
		font-size: var(--font-size-600);
		line-height: var(--line-height-600);
		font-weight: 500;
	}

	&__media {
		margin-bottom: 3rem;
	}
	&__body {
		p, ol, li {
			&:not(:last-child){
					margin-bottom: 4rem;
			}
		}
	}
}
