table {
  border-collapse: collapse;
  border: 0;
  width: 100%;
  margin-bottom: var(--globalMargin);
  tr {
    border-bottom: 1px solid var(--lightGray);
  }
  td, th {
    text-align: left;
    padding: calc(#{var(--globalPadding)} / 2);
  }
  thead {
    border-bottom: 2px solid var(--primaryColor);
    th {
      font-weight: bold;
    }
  }
}
