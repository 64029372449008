@use "../_config/colors";
@use "../mixins/background-svg";
@use "../mixins/mq";
@use "svg";

//@use "../mixins/background-svg" as backSvg;


.font-size-200 {
  font-size: var(--font-size-200);
  line-height: var(--line-height-200);
}

.font-size-300 {
  font-size: var(--font-size-300);
  line-height: var(--line-height-300);
}

.font-size-400 {
  font-size: var(--font-size-400);
  line-height: var(--line-height-400);
}

.font-size-600 {
  font-size: var(--font-size-600);
  line-height: var(--line-height-600);
}

.font-size-700 {
  font-size: var(--font-size-700);
  line-height: var(--line-height-700);
}

.font-size-800 {
  font-size: var(--font-size-800);
  line-height: var(--line-height-800);
}

.font-size-900 {
  font-size: var(--font-size-900);
  line-height: var(--line-height-900);
}


.font-weight-600 {
  font-weight: 600;
}

.font-weight-500 {
  font-weight: 500;
}

.font-weight-400 {
  font-weight: 400;
}

.text-align-center {
  text-align: center;
}

.uppercase {
  text-transform: uppercase;
}


strong,
b {
  @extend .font-weight-500;
}

// use inherit
/*
p {
  @extend .font-size-400;
}
*/
.h1,.h2,.h3,
h1,h2,h3 {
  @extend .font-weight-500;

}

h1, .h1 {
  @extend .font-size-900;
  letter-spacing: 1.5px;
  margin-bottom: 3rem;
}

h2, .h2 {
  @extend .font-size-800;
  margin-bottom: 3rem;
  letter-spacing: 0.92px;
}

h3, .h3 {
  @extend .font-size-700;
  margin-bottom: 3rem;
}

h4, .h4 {
  @extend .font-size-600
}

.header-primary {
    color: var(--color-primary-1);
    margin: 0;

}

::marker {
  color: var(--color-primary-1);
}


.font-color-white {
  color: var(--color-white);
}

.font-color-primary {
  color: var(--color-primary-1);
}

blockquote {
  margin-bottom: var(--spacing-50);
  text-align: center;
  color: var(--color-primary-1);
  font-style: italic;
  @extend .font-size-800;


  strong,
  b {
      font-style: normal;
  }

  /*
  quotes: "„" "”" "," "’";

  &:before {
    content: open-quote;
  }

  &:after {
    content: close-quote;
  }
  */
  sub {
    font-size: var(--font-size-400);
    line-height: 38px;
  }

  @include mq.mq($from: large) {

    margin-bottom: var(--spacing-120);
  }
}

.more-link-block {
    text-align: center;
    margin: 4rem 0 0;
    @include mq.mq($from: large) {
      margin: 8rem 0 0;

      &--right-on-larger {
        text-align: right;
      }
    }
    @include mq.mq($from: xxlarge) {
      &--right-on-larger {
        margin-right: -114px;
      }
    }
}

.styled {

  p,ul,ol {
    &:not(:last-child){
      margin-bottom: var(--spacing-paragraph);
    }
  }

}

/*
.styled {

  .font-size-600 + *,
  .font-size-400 + * {
    margin-top: var(--spacing-20);
  }

  .font-size-700 + *,
  .font-size-800 + * {
    margin-top: 3rem;

    @include mq.mq($from: large) {
      margin-top: var(--spacing-40);
    }
  }

  .font-size-900 + * {
    margin-top: var(--spacing-40);
  }

  figcaption {
    padding-top: var(--spacing-20);
    text-align: left !important;
    @extend .font-size-300;
  }

  .iframe-wrapper + *,
  figure:not(.iframe__poster) + * {
    margin-top: var(--spacing-40);

    @include mq.mq($from: large) {
      margin-top: var(--spacing-80);
    }
  }

  * + blockquote {
    margin-top: var(--spacing-50);

    @include mq.mq($from: large) {
      margin-top: var(--spacing-120);
    }
  }

  * + ul,
  * + ol {
    margin-top: var(--spacing-40);

    @include mq.mq($from: large) {
      margin-top: var(--spacing-60);
    }
  }

  ul, ol {
    @extend .font-size-400;

    & + * {
      margin-top: var(--spacing-40);

      @include mq.mq($from: large) {
        margin-top: var(--spacing-60);
      }
    }

    li {
      position: relative;
      padding-left: 3rem;
      counter-increment: counter-inc;

      &:not(:first-child) {
        margin-top: 2.5rem;
      }
    }
  }

  ul {
    li:before {
      position: absolute;
      left: 0;
      top: 2px;
      display: inline-flex;
      width: 2rem;
      content: "●";
      font-size: 1.4rem;
      color: var(--color-primary-1);
    }
  }

  ol {
    li:before {
      position: absolute;
      left: 0;
      top: 0;
      display: inline-flex;
      content: counter(counter-inc) ". ";
      width: 2.5rem;
      color: var(--color-primary-1);
      font-size: inherit;
      @extend .font-weight-600;
    }
  }

  .checklist li {

    &:before {
      display: block;
      left: 0;
      top: 8px;
      width: 22px;
      height: 22px;
      content: '';
      @include background-svg.params(svg.$svg-check-circle, transparent, center, center, no-repeat, 100%, 100%, colors.$color-primary-1);
    }
  }
}
*/
