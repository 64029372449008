@use "../_base/typography";
@use "../mixins/mq";

.feature {
  margin: 0;
  padding: 0;
  counter-reset: featureCounter;


  @extend .font-size-700;
  @extend .font-weight-500;

  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-auto-rows: 1fr;
  grid-column-gap: var(--spacing-30);
  grid-row-gap: var(--spacing-40);
  
  @include mq.mq($from: medium) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include mq.mq($from: large) {
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: var(--spacing-60);
    grid-row-gap: var(--spacing-60);
  }

  &__description {

    padding-bottom: 3rem;
    border-bottom: var(--color-grey-400) .4rem solid;

    &:before {
      display: block;
      font-size: 1.5rem;
      margin-bottom: 2rem;
      counter-increment: featureCounter;
      content: counter(featureCounter, decimal-leading-zero);
    }
  }


}
