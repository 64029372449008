@use "../mixins/mq";
@use "../mixins/background-svg";
@use "../_base/svg";

.filter {
  display: block;
  position: relative;
  width: 100%;
  margin-bottom: 4rem;

  &Entry {
    opacity: 1;
    //  transition: 0.5s transform, 0.5s opacity;
    &--hide {
      position: absolute;
      left: -9999px;
    }

    &--update {
      animation: flash .25s linear;
    }
  }

  &-box {
    @include mq.mq($from: medium) {
      max-width: 50rem;
      margin: auto;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 6rem;
    }
  }

  &__selected {
    font-weight: 500;
    border: 0;
    padding: 0 3rem 0 0;
    min-height: 3.6rem;
    font-size: 1.9rem;
    display: block;
    width: 100%;
    appearance: none;
    font-family: inherit;
    text-align: left;
    border-bottom: .2rem solid var(--color-black);
    border-radius: 0;
    outline: none;
    @include background-svg.params(svg.$svg-plus, transparent, center, right, no-repeat, 2rem,2rem );

    &:focus {
      outline: none;
    }
  }

  &__options {

    margin:  2rem 0 0;
    list-style: none;
    padding: .7rem 2rem;

    box-shadow: 0 2px 20px rgba(0,0,0,.2);
    background-color: var(--color-white);
    position: absolute;
    z-index: 2;
    top: 100%;
    left: 0;
    right: 0;
    display: none;
    .active + & {
      display: block;
    }

    @include mq.mq($from: medium) {
      margin-left: -20px;
      margin-right: -20px;
    }
  }

  &__item {
    &:not(:first-child){
      border-top: var(--color-primary-2) 1px solid;
    }
    display: block;
  }

  &__button {
    display: block;
    cursor: pointer;
    padding: .5rem 0;
    :checked + & {
      color: var(--color-primary-1);
    }
    :hover + & {
      color: var(--color-primary-1);
    }
  }
}

.filter__wrap {
  min-height: 80vh;
}

.filter__load-more {
  display: flex;
  justify-content: center;
  margin: 6rem 0 3rem;
}

@keyframes flash {
  0% {
    opacity: 0;
    transform: translateX(-15px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }

}
