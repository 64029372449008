.lazy {
  opacity: 0;
  transition: opacity 0.2s ease;

  &.loaded {
    opacity: 1;
  }
}

.lazy:not(img)[data-was-processed="true"] {
  opacity: 1;
}
