@use "../_base/svg";
@use "../_config/colors";
@use "../_base/typography";
@use "../utils/visually-hidden";
@use "../mixins/background-svg";
@use "../mixins/mq";
@use "../mixins/col";

.field__row {
		column-gap: var(--spacing-30);
	* {
		font-family: inherit
	}

}

.field-group {
	position: relative;
	margin-top: 1.8rem;

	@include mq.mq($from: large) {
		margin-top: 5rem;
	}


	&--half {
		width: 100%;

		@include mq.mq($from: large) {
			width: calc(calc(100% / 2) - 1.5rem);
		}
	}

	&--third {
		width: 100%;

		@include mq.mq($from: large) {
			width: calc(calc(100% / 3) - 2rem);
		}
	}

	&--large {
		width: 100%;
	}

	&--medium {
		width: 100%;

		@include mq.mq($from: large) {
			@include col.params(4, 6, var(--spacing-60));
		}
	}

	&--small {
		width: 100%;

		@include mq.mq($from: large) {
			@include col.params(2, 6, var(--spacing-60));
		}
	}

	.row & {



		& + .field-group {


		}
	}

	&--bordered {
		border-bottom: 1px solid var(--color-black);

		&.field-group--has-errors {
			border-bottom: 2px solid var(--color-error);

			&:before {
				position: absolute;
				left: -7px;
				right: -7px;
				bottom: 0;
				height: 100%;
				display: block;
				content: '';
				background-color: #F21C2810;
				z-index: 0;
			}
		}
	}

	&--checkbox {
		margin-top: var(--spacing-60);
	}

	&--html {
		margin-top: var(--spacingWide);
		color: var(--color-black);

		@include mq.mq($from: large) {
			margin-top: 10rem;
		}
	}

	&--block {
		margin-top: 37px;

		@include mq.mq($from: large) {
			margin-top: var(--spacing-60);
		}
	}

	&--firstName {
		margin-top: 57px;
	}



	&--radio_group {

		.field__label {
			min-width: 100%;
		}
		@include mq.mq($from: medium) {
			display: flex;
			flex-direction: row;
	    flex-wrap: wrap;
			column-gap: 2.5rem;
		}
	}

}


.field__input {
	position: relative;
	display: block;
	min-height: 4rem;
	max-width: 100%;
	border: 0;
	width: 100%;
	outline: none;
	background: none;
	@extend .font-size-400;

	&--file {
		width: auto;
		min-height: unset;
	}
}

.field__label {
	position: relative;
	display: block;
	@extend .font-size-400;
	font-weight: 500;

	.field-group--block & {
		position: absolute;
		left: 0;
		top: 0.2rem;
		width: 100%;
		transition: all var(--animationDuration) var(--animationEase);
		cursor: pointer;
	}

	.field-group--block-animated & {
		font-size: 1.7rem;
		line-height: 2rem;
		color: var(--color-grey-200);
		@extend .font-weight-400;
		transform: translateY(-2.7rem);
	}

	.field-group--textarea.field-group--block-animated & {
		// transform: translateY(-3.5rem);
	}

	&.required {
		&:after {
			content: '*';
		}
	}
}


input[type=checkbox],
.field__input--check,
.field__input--radio {
	@extend .visually-hidden;
}


.field__sub-label {
	position: relative;
	display: block;
	padding-top:0;
	line-height: var(--spacing-40);
	margin-top: var(--spacing-20);
	@extend .font-size-400;
	padding-left: 5.1rem;
	min-height: var(--spacing-40);
	cursor: pointer;
	font-weight: 500;
	padding-right: 1.5rem;

	@include mq.mq($from: large) {
		padding-right: 3.4rem;
	}


	&:before {
		position: absolute;
		left: 0;
		top: 0;
		display: block;
		width: 3.8rem;
		height: 3.8rem;
		content: '';
		border: 1px solid var(--color-primary-1);
	}

	.field__input--radio + & {
		&:before {
			border-radius: 50%;
		}
	}

	input[type=checkbox]:checked + &,
	.field__input--check:checked + & {
		&:before {
			@include background-svg.params(svg.$svg-check, transparent, center, center, no-repeat, 20px, $fillColor: colors.$color-primary-1);
		}
	}

	.field__input--radio:checked + & {
		&:after {
			position: absolute;
			left: .3rem;
			top: .3rem;
			display: block;
			content: '';
			width: 3.4rem;
			height: 3.4rem;
			background-color: var(--color-primary-1);
			border-radius: 50%;
		}
	}

	&--inline {
		display: inline-flex;
		margin-right: var(--spacing-40);
	}
}
.field-group--textarea {
	border-bottom: none;
	margin-top: 7rem;

	.field__label {
		//top: -3.6rem;
		font-size: 2.1rem;
		font-weight: 500;
		transform: unset;
	}
}

.field__input--textarea {
	transition: height var(--animationDuration) var(--animationEase);
	height: 33.7rem!important;
	border: 1px solid;
	padding: 10px;

	@include mq.mq($from: medium){
		height: 24.9rem!important;
	}
	@include mq.mq($from: large){
		height: 36.8rem!important;
	}

	&::after {

		background-repeat: no-repeat;
		background-size: 2.5rem;
		bottom: 3px;
		content: "";
		height: 2.5rem;
		pointer-events: none;
		position: absolute;
		right: 0;
		width: 2.5rem;
	}
}

.ff-form-errors,
.ff-form-success {
	margin-bottom: var(--spacing-60);
	@extend .font-size-700;
	@extend .font-weight-600;
}

.ff-form-success {
	color: var(--color-success);
}

.ff-form-errors {
	color: var(--color-error);
}


.ff-errors {
	position: absolute;
	top: 100%;
	font-size: 1.6rem;
	line-height: 3.2rem;
	letter-spacing: 0.1rem;
	@extend .font-weight-500;
	color: var(--color-error);

	:is(.field-group--checkbox_group) + & {
		position: relative;
		top: auto;
		margin-top: var(--spacing-10);
	}
}

.field-group--submit {
	.action + .action {
		margin-left: var(--spacing-20);
	}
}
.field--hide {
	display: none;
}
.field-group--zipCode {
	width: 100%;

	@include mq.mq($from: medium) {
		width: calc(30% - 3rem);
	}
}
.field-group--place {
	width: 100%;

	@include mq.mq($from: medium) {
		width: 70%;
	}

}
.field-group--submit {
	margin-top: 4rem;

	@include mq.mq($from: large) {
		margin-top: 6rem;
	}
}
